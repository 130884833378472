import React, { useState } from "react"
import PropTypes from "prop-types"
// import { Helmet } from "react-helmet"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styles from "../styles/photo-gallery.module.scss"
import "../styles/lightgallery-mod.scss"
import {
  LightgalleryProvider,
  LightgalleryItem,
  // withLightgallery,
  // useLightgallery,
} from "react-lightgallery"

const PhotoItem = ({
  alt,
  caption,
  carouselThumb,
  credit,
  galleryThumb,
  group,
  original,
  position,
  thumbSrcSizes,
}) => {
  const hasCaption = !!(caption || credit)
  const captionSelector = hasCaption ? `#caption-${position}` : false

  return (
    <figure
      className={styles.photoItem}
      itemProp="associatedMedia"
      itemScope
      itemType="http://schema.org/ImageObject"
    >
      <LightgalleryItem
        // https://sachinchoolur.github.io/lightgallery.js/docs/api.html
        group={group}
        src={original.images.fallback.src}
        subHtml={captionSelector ? captionSelector : null}
        thumb={carouselThumb}
      >
        <a
          data-sizes={thumbSrcSizes}
          data-src={original.images.fallback.src}
          data-srcset={original.images.fallback.srcSet}
          href="#"
          itemProp="contentUrl"
        >
          <GatsbyImage
            alt={alt}
            image={galleryThumb}
            itemProp="thumbnail"
            sizes={thumbSrcSizes}
          />
          {hasCaption && (
            <figcaption
              className={`caption`}
              id={`caption-${position}`}
              itemProp="caption description"
            >
              {caption}
              {credit && (
                <div className="credit">
                  Photo Credit:{" "}
                  <span className="name" itemProp="copyrightHolder">
                    {credit}
                  </span>
                </div>
              )}
            </figcaption>
          )}
        </a>
      </LightgalleryItem>
    </figure>
  )
}

PhotoItem.propTypes = {
  alt: PropTypes.string,
  caption: PropTypes.string,
  carouselThumb: PropTypes.string,
  credit: PropTypes.string,
  galleryThumb: PropTypes.object.isRequired,
  group: PropTypes.string.isRequired,
  original: PropTypes.object.isRequired,
  position: PropTypes.number,
}
PhotoItem.defaultProps = {
  alt: ``,
}

const PhotoGallery = ({ galleryData, galleryMeta, thumbSrcSizes, group }) => {
  const [open] = useState(true)

  return (
    <>
      <div itemScope itemType="http://schema.org/ImageGallery">
        {open && (
          <LightgalleryProvider
            lightgallerySettings={{
              // https://sachinchoolur.github.io/lightgallery.js/docs/api.html
              actualSize: false,
              download: false,
              speed: 500,
            }}
            // https://github.com/VLZH/react-lightgallery#supported-events
            // onBeforeOpen={() => console.info("onBeforeOpen")}
          >
            <div className={styles.photoGallery}>
              {galleryData
                // Aggregate the graphQL query data with meta data object
                .map(item => {
                  // `thumbnails` - cropped, rendered in main page content
                  // `photos` - the full size assets for display in lightbox
                  const { thumbnails, photos } = item.node

                  // The objects from GraphyQL query to pass to GatsbyImage
                  const galleryThumb = getImage(thumbnails.gatsbyImageData)
                  const originalPhotoData = getImage(photos.gatsbyImageData)
                  // A small resize for use in the lightbox pager
                  const carouselThumb = thumbnails.resize.src

                  // Match caption/alt data from galleryMeta prop to photos
                  const metaKey = item.node.relativePath
                  const { alt, caption, credit } = galleryMeta?.[metaKey] || {}
                  const position = Object.keys(galleryMeta).indexOf(metaKey)

                  return {
                    alt,
                    caption,
                    carouselThumb,
                    credit,
                    galleryThumb,
                    metaKey,
                    originalPhotoData,
                    photos,
                    position,
                    thumbnails,
                  }
                })
                // Sort by order of appearance in meta object
                .sort((a, b) => {
                  return a.position - b.position
                })
                // Return JSX
                .map((item, idx) => {
                  return (
                    <PhotoItem
                      alt={item.alt}
                      caption={item.caption}
                      carouselThumb={item.carouselThumb}
                      credit={item.credit}
                      galleryThumb={item.galleryThumb}
                      group={group}
                      key={idx}
                      original={item.originalPhotoData}
                      position={idx}
                      thumbSrcSizes={thumbSrcSizes}
                    />
                  )
                })}
            </div>
          </LightgalleryProvider>
        )}
      </div>
    </>
  )
}

export default PhotoGallery
